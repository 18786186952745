<script>
export default {
  name: "InfoRow",
  props: {
    label: {
      type: String,
      default: ""
    },
    value: {
      type: [String,Number],
      default: ""
    }
  }
};
</script>

<template>
  <div class="info-row">
    <div class="label"> {{ label }}</div>
    <div class="value"> {{ value }}</div>
  </div>
</template>

<style scoped lang="less">
.info-row {
  padding: 8px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #939598;
  font-size: 14px;

  .label {
    flex: 1;
  }
  .value {
    flex: 1;
    text-align: right;
    color: #555555;
  }
}
</style>