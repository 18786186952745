
<template>
  <div class="flavor-item" >
    <van-image :src="flavor.flavorPicture" fit="contain" class="flavor-icon"/>
    <div class="flavor-name-tag">
      <div class="flavor-name">{{flavor.flavorName}}</div>
      <div class="flavor-tag-content">
        <span  v-for="(tag,index) in flavor.labelList" :key="index"  class="flavor-tag">{{tag.labelName}}</span>
      </div>
    </div>
    <div class="select-icon" v-if="showSelect">
      <img v-if="select"   src="@/assets/icons/brand/icon_select_flavor.png"/>
      <div v-else class="normal-icon"></div>
    </div>

  </div>
</template>

<script>
export default {
  name: "FlavorItem",
  props: {
    flavor : {
      type:Object,
      default:()=> {
        return {
        }
      }
    },
    select: {
      type:Boolean,
      default:false
    },
    showSelect: {
      type:Boolean,
      default:true
    }
  },
  methods: {
  }
};
</script>


<style scoped lang="less">

.flavor-item {
  margin-top:15px;
  border: 1px solid #F1F2F2;
  padding:10px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .flavor-icon {
    width: 60px;
    height: 60px;
    min-width:60px;
    min-height:60px;
    border-radius: 10px;
  }

  .flavor-name-tag {
    margin-left:10px;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-x: scroll;
    .flavor-name {
      font-size:15px;
    }
    .flavor-tag-content {
      margin-top:10px;
      display: flex;
      flex: 1;
      font-size: 10px;
      flex-direction: row;
      align-items: center;
      overflow-x: scroll;
      .flavor-tag {
        border-radius: 8px;
        background: var(--modeActiveBackground) !important;
        color: var(--modeActiveTextColor) !important;
        padding: 4px 8px;
        margin-right:4px;
      }
    }
  }


  .select-icon {
    width:24px;
    min-width:24px;

    img {
      width:100%;
      height:100%;
    }
  }
  .normal-icon {
    width:24px;
    height:24px;
    border-radius: 50%;
    border: 1px solid #DDD;
  }
}
</style>