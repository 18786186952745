import { requestGet, requestGetForm, requestPostForm } from "@/config/http-json";

const produce = {
  async brandList() {
    const resp = await requestGetForm("/omni/produce/selectBrandList");
    return resp
  },

  async scanBrandQrcode(data) {
    const resp = await requestGetForm("/omni/produce/selectQrCodeStatus",data);
    return resp
  },
  async uploadTagInfo(data) {
    const resp = await requestPostForm("/omni/produce/uploadNfcData",data);
    return resp
  },
  async scanBrandRecordList(data,showProgress = false) {
    const resp = await requestGetForm("/omni/produce/selectScanRecord",data,{showProgress});
    return resp
  }
}

export default produce