<script>
export default {
  name: "ScanQRCode",
  props: {
    hasData: {
      type: Boolean,
      default:false
    },
    scanType: {
      type:Number,
      default : 1
    },
    disabledNfcButton: {
      type:Boolean,
      default: false
    }
  },
  data() {
    return {

    }
  },
  methods: {
    onQRCodeClick() {
      this.$emit('onQRCodeClick')
    },
    onConnectNfcClick() {
      this.$emit('onConnectNfcClick')
    },
    onTipsClick() {
      if (this.scanType === 0) {
        this.onConnectNfcClick()
      }else {
        this.onQRCodeClick()
      }
    }
  },
  computed: {

    enableNFCStyle() {
      if (this.disabledNfcButton) {
        return { background: "#a7a9ac", color: "#EEEEEE" };
      } else {
        return { background: "#86a1a9", color: "white" };
      }
    },
    tips() {
      if (this.scanType === 0) {return 'Click icon to connect nfc'}
      else {return 'Click icon to scan QR Code'}
    },
    scanCodeButtonText() {
      if (this.hasData) {
        return 'Continue to Scan'
      }
      return 'Scan Code'
    }
  }
};
</script>

<template>
  <div class="content">
    <img v-if="scanType === 0" src="@/assets/icons/brand/icon_brand_scan_nfc.png" class="qrcode" @click="onConnectNfcClick"/>
    <img v-if="scanType === 1" src="@/assets/icons/brand/icon_qrcode.png" class="qrcode" @click="onQRCodeClick"/>

    <div v-if="!hasData" style="margin-top:15px;color: #939598" @click="onTipsClick">{{tips}}</div>
<!--    <van-button v-if="scanType === 0"  :disabled="disabledNfcButton" color="#86A1A9" :style="enableNFCStyle" style="border-radius: 10px; padding:20px 40px;margin-top:20px" @click="onConnectNfcClick">Connect NFC</van-button>-->
<!--    <van-button v-if="scanType === 1"  color="#86A1A9" style="border-radius: 10px; padding:20px 40px;margin-top:20px" @click="onQRCodeClick">{{scanCodeButtonText}}</van-button>-->
  </div>
</template>

<style scoped lang="less">
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #939598;
  font-size:14px;
  padding:20px;

  .qrcode {
    width: 70px;
    height: 70px;
  }
}
</style>