<template>
  <div class="content">
    <div class="brand-info row-margin showinfo">
      <van-image :src="brand.brandPicture" fit="contain" alt="" class="img"/>
      <div class="name">{{ brand.brandName }}</div>
      <img v-if="showInfo"   src="@/assets/icons/brand/icon_select_flavor.png" class="select-icon"/>
    </div>
    <FlavorItem class="row-margin" :show-select="showInfo" :select="showInfo" :flavor="flavor"/>

    <template  v-if="showInfo">
      <div class="brand-info row-margin showinfo" style="margin-top:10px;">
        <van-image :src="require('@/assets/icons/brand/icon_popup_up.png')" fit="contain" alt="" class="img"/>
        <div class="name" style="font-size:15px">Update popup page</div>
        <img src="@/assets/icons/brand/icon_select_flavor.png" class="select-icon"/>
      </div>
    </template>

    <template v-if="showInfo">
      <div style="height:6px;background: #f6f6f6; margin:20px 0"></div>
      <div style="display: flex;flex-direction: column;padding:0 15px;" v-for="(item,index) in codeDeviceInfo"
          :key="index">
        <InfoRow :label="item.label" :value="item.value"/>
      </div>
    </template>


    <div style="height:6px;background: #f6f6f6; margin:20px 0 0 0"></div>
    <ScanBoxQRCode :scan-type="scanType" :has-data="hasData"  :disabled-nfc-button="disabledNfcButton"
        @onQRCodeClick="onQRCodeClick"
        @onConnectNfcClick="onConnectNfcClick"/>

    <div class="submit-button">
      <van-button  v-if="showInfo && scanType === 1" color="#86A1A9" @click="onSubmit" text="Submit" style="width:100%;border-radius: 6px"/>
      <van-button :color="themeMainColor" @click="onBackHome" text="Back to Home" style="width:100%;border-radius: 6px"/>
    </div>
  </div>
</template>

<script>
import FlavorItem from "@/components/FlavorItem.vue";
import ScanBoxQRCode from "@/components/ScanBoxQRCode.vue";
import InfoRow from "@/components/InfoRow.vue";
import produce from "@/api/produce";
import { mapState } from "vuex";

export default {
  name: "FlavorInfo",
  components: { InfoRow, ScanBoxQRCode, FlavorItem },
  props: {
    brand: {
      type: Object,
      default: () => {
        return {};
      }
    },
    flavor: {
      type: Object,
      default: () => {
        return {};
      }
    },
    qrCodeInfo: {
      type: Object,
      default: () => {
        return {};
      }
    },
    connectTagInfo: {
      type: Object,
      default: () => {
        return {};
      }
    },
    showInfo: {
      type: Boolean,
      default: false
    },
    boxNumber: {
      type: Number,
      default: 0
    },
    tagNumber: {
      type: Number,
      default: 0
    },
    scanType: {
      type: Number,
      default: 1
    },
    disabledNfcButton: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onBackHome() {
      this.$emit("onBackHome");
    },
    async onQRCodeClick() {
      this.$emit("onQRCodeClick");
    },

    async onConnectNfcClick() {
      this.$emit("onConnectNfcClick");
    },
    onSubmit() {
      this.$emit("onSubmit")
    }
  },
  computed: {
    ...mapState("theme",["themeMainColor"]),
    hasData() {
        return this.boxNumber > 0;
    },
    codeDeviceInfo() {
      if (this.scanType === 1) {
        if (this.qrCodeInfo) {
          const info = [];
          const { id, deviceTypeId, tagNumber, deviceTypeName } = this.qrCodeInfo;
          info.push({ label: "Box Quantity", value: this.boxNumber });
          info.push({ label: "Quantity", value: tagNumber });
          info.push({ label: "Model", value: deviceTypeName });
          return info;
        }
      } else {
        if (this.connectTagInfo) {
          const info  = []
          const { tagNumber, deviceModel } = this.connectTagInfo;
          info.push({ label: "Quantity", value: this.boxNumber });
          info.push({ label: "Model", value: deviceModel });
          return info
        }
      }
      return [];
    }
  }
};
</script>


<style scoped lang="less">
.content {
  display: flex;
  flex-direction: column;
  color: #555555;

  .showinfo {
    padding:10px;
    border-radius: 10px;
    border: 1px solid #F1F2F2;
  }
  .brand-info {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .img {
      width: 60px;
      height: 60px;
    }

    .name {
      flex:1;
      margin-left:10px;
      font-size: 18px;
    }
  }

  .row-margin {
    margin-left: 15px;
    margin-right: 15px;
  }

  .submit-button {
    background: white;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 15px;
  }
}
.select-icon {
  width:24px;
  height:24px;
  min-width:24px;
  min-height:24px;

  img {
    width:100%;
    height:100%;
  }
}
</style>